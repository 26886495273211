import React, { useState } from "react"
import "./BoardTemplate.scss"

import OneSlideViewSlider from "../one-slide-view-slider/OneSlideViewSlider"

import { getImageUrl } from "../../services/Utils"

const BoardTemplate = ({
  item,
  title,
  description,
  sizes,
  amount,
  images,
  staticContent,
  itemKey,
}) => {
  const [boardSizes, setBoardSizes] = useState()

  const getSizes = data => {
    if (data && data.size) {
      setBoardSizes({
        size: data.size,
        amount: data.amount,
        previewIcon: data.previewIcon,
      })
    }
  }
  return (
    <div key={item.id} className="board-template row mt-4 mb-6">
      <div className="row">
        <h1 className="col-lg-12 mb-3">{title}</h1>
      </div>
      <div
        className="description col-lg-8"
        dangerouslySetInnerHTML={{ __html: description }}
      ></div>
      {/* <div className="description col-lg-12 mt-4">
        {staticContent["Format"]}: {sizes}
      </div>
      <div className="description col-lg-12">
        {staticContent["Planes"]}: {amount}
      </div> */}
      {item?.orderText && item?.orderUrl ? (
        <div className=" mt-2 mb-5">
          <a
            href={item?.orderUrl}
            type="button"
            className="btn btn-primary btn-lg mt-4 col text-white"
          >
            {item?.orderText}
          </a>
          <div className="col-lg-10"></div>
        </div>
      ) : null}
      <div className="position-relative">
        <OneSlideViewSlider
          itemKey={itemKey}
          items={Array.isArray(images) ? images : [images]}
          bottomSizes={true}
          getSizes={getSizes}
        />
        <div className="mini-icon-item d-flex align-items-center justify-content-center pt-3 curs-P position-absolute">
          <img src={getImageUrl(boardSizes?.previewIcon)}></img>
        </div>
        <div className="board-description-text w-100">
          {staticContent && (
            <div className="">
              {staticContent["Format"]}:&nbsp;{boardSizes?.size}
              &nbsp;&nbsp;&nbsp; {staticContent["Planes"]}:&nbsp;
              {boardSizes?.amount}
            </div>
          )}
        </div>
      </div>
    </div>
  )
}

export default BoardTemplate
