import React from "react"
import "../scss/GalleryPage.scss"
import Seo from "../components/seo/seo"

import BoardTemplate from "../shared/board-template/BoardTemplate"

import Header from "../components/header/header"
import Footer from "../components/footer/footer"

const Gallery = props => {
  const gallery = props.pageContext.resultGallery
  const menu = props.pageContext.menu
  const staticHeaderPage = props.pageContext.staticHeaderPage
  const staticContent = props.pageContext.staticContent
  gallery.sort(function (a, b) {
    return a?.position - b?.position
  })
  return (
    <div>
      <div className="container gallery-page">
        <Header
          menu={menu}
          siteTitle={staticContent["<title>"]}
          contents={staticHeaderPage}
          displayLogoLinks={false}
        />
        <Seo
          keywordsContent={staticContent["<meta> keywords"]}
          description={staticContent["<meta> description"]}
          title={staticContent["<title>"]}
        />
        {gallery && gallery.length ? (
          <div className="row">
            <div className="col">
              {gallery.map((item, index) => {
                return (
                  <BoardTemplate
                    key={item.id}
                    staticContent={staticContent}
                    item={item}
                    title={item.title}
                    description={item.description}
                    sizes={item.size}
                    amount={item.amount}
                    images={item.images}
                    itemKey={index}
                  />
                )
              })}
            </div>
          </div>
        ) : null}
      </div>

      <Footer
        recaptchaSettings={props.pageContext.recaptchaSettings}
        staticContactsForm={props.pageContext.staticContactsForm}
        staticContactsPage={props.pageContext.staticContactsPage}
      />
    </div>
  )
}

export default Gallery
